.adminLeftNavMenu {
  margin: 0;
  width: 100%;
}

.adminLeftNav {
  padding: 0;
}

.adminSearchBar {
  width: 100%;
}

.userSearchRow {
  border-bottom: 1px solid #e4e9f5;
  padding: 1em;
  cursor: pointer;
}

.fullWidthInput {
  width: 100%;
}

.userSearchRow:hover {
  opacity: 0.5;
}

.userSearchRowTitle {
  font-weight: 700;
}

.detailsRow {
  width: 40%;
  display: inline-block;

  cursor: pointer;
  margin-right: 5%;
}

.detailsRow:hover {
  opacity: 0.5;
}

.readonly {
  background-color: #dedede;
}

.cell {
  width: 33%;
  word-break: break-all;
  border: 1px solid grey;
  padding: 1em;
  text-align: center;
}

.modalInputBox {
  display: inline-block;
  width: 40%;
  margin-bottom: 1.5em;
  vertical-align: middle;
}

.hoverHighlight:hover {
  background-color: #ebebeb;
}
