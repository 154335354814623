@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

html,
#root,
body {
  height: 100%;
  max-height: 100%;
  margin: 0;
}

#root {
  overflow-y: auto;
}

h1 {
  margin-bottom: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: white;
  margin: 0;
  padding: 0;
  color: var(--primary-color);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  width: 100%;
}

:root {
  --primary-color: #2f3c5b;
  --stepladder-green: #51e9c1;
  --grey-wording: grey;
}

.boxShadow {
  -webkit-box-shadow: 0px 0px 10px 0px rgb(158 158 158 / 75%);
  -moz-box-shadow: 0px 0px 10px 0px rgb(158 158 158 / 75%);
  box-shadow: 0px 0px 10px 0px rgb(158 158 158 / 75%);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--stepladder-green);
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background: #47c3a3;
}

body {
  background-color: white;
}

.circlePicker {
  margin-top: 5px;
}

.transition {
  transition: all 0.3s ease;
}

.pick_other_circles > div {
  padding: 0.75em 1em;
  display: inline-block;
  border-radius: 7px;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.pick_other_circles > div > div:first-child {
  font-weight: 700;
}
.pick_other_circles > div > div:last-child {
  font-weight: 500;
  font-size: 0.8em;
}
.pick_other_circles {
  padding: 0.2em;
  margin-bottom: 0px;
  margin-top: 35px;
}
.pick_other_circles .selected {
  background-color: var(--primary-color);
  color: white;
}

.circlePicker .title {
  font-weight: 900;
  font-size: 0.7em;
  color: purple;
}

.circlePicker .p-card-body {
  padding: 0;
}

.circlePicker .p-card-title {
  padding: 0.7em;
}

.circlePicker .amount {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 1.4em;
}

.circlePicker img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 8em;
}

.circlePicker .inner_content > div {
  display: inline-block;
  width: 32%;
  margin-bottom: 2em;
}

.circlePicker .inner_content {
  padding: 1em;
}
.circlePicker .p-card-content {
  padding: 0;
}

.circlePicker sup {
  font-size: 0.4em;
}

.circlePicker {
  z-index: 2;
  margin-bottom: 1.5em;
  position: relative;
}
.p-button {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  border-width: 3px;
  border-radius: 6px;
  font-weight: 700;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: var(--primary-color);
}

.p-button.p-button-outlined:enabled:hover {
  color: #171e2d;
}

.p-button:enabled:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white
}

@media only screen and (min-width: 1450px) {
  body {
    font-size: 19px;
  }
}

@media only screen and (min-width: 600px) {
  .circleImage{
    width:100px !important;
    margin-left: 0 !important;
    margin-bottom:25px !important
  }
  .quizPageLayout{
    width:400px !important
  }
  div.highlightClickable {
    width:350px;
    height:300px;
    cursor:pointer;
    display: inline-block;
  }
  div.highlightClickable:hover{
    opacity:0.75;
  }
  div.highlights{
    text-align: center;
    margin-top:-140px;
    display: block;
  }

  div.highlightPopup{
    width:30vw
  }
  .highlightPopup img{
    width:100px
  }
  
  .circleView .scrollTarget {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }
  div.circleViewBack {
    display: inline-block;
    position: absolute;
  }
  div.circleViewTabContent {
    flex-grow: 1;
    overflow-y: auto;
  }
  .articleListClient {
    width: 80%;
    margin-left: 10%;
    margin-top: 80px;
    text-align: center;
  }
  .articleListClient .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px !important;
  }
  .articleListClient > div {
    width: 30%;
    border-radius: 10px;
    background-color: #dedede;
    border-bottom: 0;
    display: inline-block !important;
    height: 200px;
    position: relative;
    overflow: hidden;
  }
  .articleListClient div .description {
    color: white;
  }
  .articleListClient > div > div:last-child {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px;
    background-color:#2f3c5bbc;
    color: white;
    margin-left: 0 !important;
  }
  .articleListClient .coverimage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
  }
  .articleListClient > div {
    margin-right: 2%;
  }
  .circleView h2 {
    display: block !important;
  }
  .desktopTopMenuContainer {
    position: relative;
  }
  div.feedComponentContainer {
    display: inline-block;
    width: 40%;
    background: #f2f2fb;
    padding: 30px;
    text-align: left;
    border-radius: 10px;
    vertical-align: top;
    height: auto
  }
  div.feedComponentContainer .content {
    padding-left: 0;
  }
  div.feed > div:nth-child(odd) {
    margin-right: 5%;
  }

  div.feed {
    text-align: center;
  }
  div.platformContent h2 {
    display: none;
  }
  div.platformContent .headerDescription {
    display: none;
  }
  div.platformContent h2.show-desktop {
    display: block;
}

  div.platformContent .onboarding-subtitle {
    display: none;
  }
  div.categories {
    text-align: center;
    width: 100%;
    margin-left: 0;
  }

  .circleInviteButton > button:first-child {
    margin-right: 15px;
  }

  div.circleCarousel .add {
    cursor: pointer;
  }

  div.overlayTransparent {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div.overlayTransparent > div {
    position: relative;
    width: 500px;
    border-radius: 30px;
  }
  div.overlayTransparent > div > div {
    padding: 15px 25px;
  }
  .xScrollSelect > div:last-child {
    margin-right: 0;
  }
  .xScrollSelect {
    text-align: center;
  }
  .xScrollSelect > div:hover {
    opacity: 0.6;
    cursor: pointer;
  }
  div.overlayTransparent button {
    text-align: center;
    width: 100%;
    padding: 15px;
  }
  div.circleViewTabs > div {
    cursor: pointer;
  }
  div.chatBox {
    position: fixed;
    max-width: 700px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  div.circleCarouselBox {
    height: 200px;
    width: 250px;
    cursor: pointer;
    text-align: left;
  }
  div.platformContent > div {
    display: inline-block;
  }
  div.circleCarousel {
    width: 100%;
    text-align: center;
  }
  .circleCarouselBox:hover {
    opacity: 0.8;
  }

  div.article {
    padding: 40px;
  }
  div.layoutContainer > div:last-child {
    width: 500px;
  }
  div.platformMobileMenu {
    display: none;
  }
  .blueBox.investor {
    width: 100%;
  }
  .formComponent.billing_address {
    display: block;
    width: 100%;
  }
  .circleView {
    background: rgba(0, 0, 0, 0.7) !important;
  }
  .circleView > div {
    width: 700px;
    right: 0;
    top: 0;
    height: 100vh;
    position: fixed;
    background-color: white;
  }
  div.circleViewBack {
    cursor: pointer;
  }

  .congratsInvestor img {
    display: none;
  }
  .congratsInvestor .investorTitle {
    text-align: left;
  }
  div.centerMiddle {
    max-width: 460px;
  }
  .terms_summary,
  .agreements {
    max-width: 100%;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(83, 83, 83, 0.2);

    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(83, 83, 83, 0.2);

    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(83, 83, 83, 0.2);
    background-color: #555;
  }

  div.tooltip .tooltiptext {
    width: 250px;
    padding: 15px;
    font-size: 12px;
  }
  .quizAnswers > div:hover {
    background-color: #f3f3f3;
  }
  .quizAnswers > div {
    cursor: pointer;
  }
  .pick_other_circles > div:hover {
    opacity: 0.7;
  }
  .circlePicker {
    max-width: 360px;
  }
  .visibleOption:hover {
    opacity: 0.4;
    cursor: pointer;
  }
  .globalToast > div {
    max-width: 300px;
    display: inline-block;
    background-color: rgb(36, 36, 36);
    border-radius: 25px;
    padding: 15px 20px;
    font-size: 13px;
  }
  div.globalToast {
    text-align: center;
    background-color: transparent;
  }
}

.layoutMenuIcon {
  display: none;
}

.layoutContainer.noFlex {
  display: block;
}
.loginMobile {
  display: none;
}
.form > div.reason_for_saving_other{
  width: 100%;
}

.video-container { position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; }

.video-container iframe, .video-container object, .video-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.mobileMenuDropdown {
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  height: 100% !important;
}

.mobileMenuDropdown > div > div {
  background-color: white;
  padding: 40px 25px;
  border-bottom: 1px solid grey;
}

.formButtonContainer button {
  margin-bottom: 10px;
}

.formButtonContainer.old {
  position: initial!important;
  padding: 0!important;
}

/* Only aplied for mobile */
@media only screen and (max-width: 599px) {
  .loginMobile {
    display: block !important;
    /* color: var(--primary-color); */
    border-radius: 5px;
    font-size: 18px;
    /* border: 1px solid var(--primary-color); */
    padding: 10px;
    color: white;
  }
  .layoutMenuIcon {
    display: block !important;
    color: var(--primary-color);
    font-size: 28px;
  }
  .layoutMenu {
    display: flex;
    justify-content: space-between;
  }

  .mobileTopMenu img {
    width: 65px;
    position: absolute;
    margin-top: 4px;
  }
  .mobileTopMenu {
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 4px #cfd1d59e;
  }
  
  .platformContent > div {
    padding: 25px;
    padding-bottom: 100px;
    max-width: 100% !important;
  }
  .hideMobile {
    display: none;
  }
  .circleInviteButton {
    display: flex;
  }
  .circleInviteButton button {
    width: 45%;
  }
  .circleInviteButton > button:first-child {
    margin-right: 10%;
  }
  .hideScrollbar::-webkit-scrollbar {
    display: none;
  }
  .circleCarousel::-webkit-scrollbar {
    display: none;
  }
  .desktopMenuOptions {
    display: none;
  }
  .p-button {
    width: 100%;
    padding: 16px;
    border-radius: 10px;
  }

  .onboardingContainer {
    position: absolute;
    overflow-y: auto!important;
    height: auto!important;
    min-height: 100%;
    /* background: yellow; */
  }

  /* div.onboardingContainer > div:last-child {
    padding-top: 0!important;
    padding-bottom: 0!important;
  } */

  .page-wrapper-header {
    min-height: 160px;
  }

  div.layoutContainer img.congratsQuiz {
    width: 100%;
    margin-top: 45px;
    margin-bottom: 25px;
  }
  div.layoutContainer {
    display: flex;
    flex-direction: column;
  }
  div.inputError {
    font-size: 12.5px;
    margin-top: 5px;
  }
  div.layoutMenu {
    width: 100%;
    min-width: auto;
  }
  .menuHelp {
    display: none;
  }
  /* ,
  div.layoutContainer  */
  div.layoutContainer > div{
    height: auto;
  }

  div.centerMiddle {
    display: block;
    flex-direction: initial;
    justify-content: initial;
    padding-bottom: 100px;
  }
  div.layoutContainer > div:last-child {
    width: 100%;
    overflow-x: hidden;
    padding: 30px;
    padding-top: 20px;
  }
  div.form > div {
    display: block;
    width: 100%;
  }
  div.form {
    max-width: 100%;
  }

  .onboardingButtonContainer {
    position: fixed;
    bottom: 0;
    background: white;
    width: 100%;
    overflow-x: hidden;
    padding: 30px;
    z-index: 60;
  }

  .onboardingButtonContainer button {
    width: 100%;
    padding: 18px 5px;
    margin-bottom: 45px;
    border-radius: 11px;
  }

  .formButtonContainer {
    width: 100%;
    position: fixed;
    bottom: 0;
    background: white;
    padding: 30px;
    right: 0px;
    z-index: 50;
    
  }
  .formButtonContainer button {
    width: 100%;
    padding: 18px 5px;
    /* margin-bottom: 45px; */
    border-radius: 11px;
  }

  .formButtonContainer button {
    margin-bottom: 10px;
  }


  div.layoutMenu {
    /* background-color: white; */
    background-color: #ffffff00;
    padding: 20px 20px 0px 20px;
    position: sticky;
    top: 0;
    z-index: 15;
    opacity: 0.98;
  }
  div.layoutMenu > div:not(:first-child),
  div.layoutMenu h2 {
    display: none;
  }
  div.layoutMenu > div:first-child img {
    margin: 0;
  }
  .layoutContainer h2 {
    font-size: 30px;
  }
  
  .getStartedForm .referralToggle {
    float: left;
    width: auto;
  }
  .getStartedForm .joint_application {
    float: right;
    width: auto;
    margin-right: 0;
  }
  .getStartedForm .joint_application .p-inputswitch {
    float: right;
  }
  /* .getStartedForm .formButtonContainer {
    float: left;
  } */
  .layoutContainer {
    /* background-image: url('./images/drawday/header.png'); */
    /* background-image: url("./images/drawday/header.png"); */
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 55;
    /* color: white; */
  }
  .onboarding-header,
  .onboarding-subtitle {
    /* !important */
    color: white;
  }

  .onboarding-header {
    /* max-width: 60%; */
    font-size: 24px!important;
  }

  .circleImage{
    width: 100px;
    display: block;
    margin: auto;
    margin: 2em auto;
  }

  .quizTitle,
  .mobile-text-center {
    text-align: center;
}

.max-size-100 {
  max-height: 100%;
  max-width: 100%;
}
}


/*Exception */
@media only screen and (max-width: 384px) {
  .fit-text {
    font-size: 0.9rem;
  }
  .p-button {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
}
}

.formComponent .p-inputtext {
  width: 100%;
}

.globalToast {
  background-color: rgb(36, 36, 36);
  position: fixed;
  width: 90%;
  border-radius: 25px;
  padding: 20px;
  color: white;
  bottom: 35px;
  left: 5%;
  z-index: 1001;
  cursor: pointer;
}
.loadingOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 500;
  color: var(--stepladder-green);
}
.loadingOverlay img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.layoutMenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: #c2dedc;
}
.layoutMenu ul li {
  padding: 15px 10px;
  margin-right: 20px;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 15px;
}
.layoutMenu ul li:hover {
  color: white;
}
.layoutMenu .logo {
  width: 55px;
  margin-bottom: 25px;
}
.layoutMenu {
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 4%;
  background-color: var(--primary-color);
  width: 423px;
  min-width: 423px;
  color: white;
}
.layoutMenu ul li.active {
  background-color: #273350;
  border-radius: 5px;
  color: white;
}
.layoutContainer > div,
.layoutContainer {
  height: 100%;
}
.layoutContainer > div:last-child {
  padding: 70px;
  overflow-y: auto;
  flex-grow: 1;
}
.layoutMenu ul i {
  margin-right: 5px;
}
.layoutMenu .menuHelp {
  position: absolute;
  bottom: 35px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}
.layoutMenu .menuHelp:hover {
  opacity: 0.7;
}
.layoutMenu .menuHelp i {
  margin-right: 5px;
}
.inputError {
  color: red;
  font-size: 11px;
  margin-top: 2px;
}
.form > div {
  display: inline-block;
  width: 44%;
  vertical-align: top;
}
div.form.inline-form > div {
  display: block;
  width: 100%;
}
.form {
  margin-top: 30px;
}

div > .onboardingButtonContainer {
  display: block;
  margin-top: 20px;
}

div > .formButtonContainer {
  display: block;
  margin-top: 20px;
}
.formComponent > div:first-child {
  font-weight: 500;
  font-size: 14px;
  color: grey;
}
.headerDescription {
  color: grey;
  font-size: 14px;
  margin-top: 15px;
  max-width: 500px;
  margin-bottom: 15px;
}
.formComponent i {
  font-size: 10px;
  margin-left: 0px;
  cursor: pointer;
}

.formComponent {
  margin-bottom: 15px;
  margin-right: 5%;
}

.centerMiddle {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
h2 {
  margin: 0;
}

.layoutMenu h1 {
  color: white;
}
.layoutMenu .phones {
  width: 90%;
  margin-top: 60px;
}
.formComponent .textInput {
  background: #f5f5f5;
  padding: 15px 15px;
  border-radius: 7px;
  border: 1px solid #ececec;
  padding-top: 30px;
  transition: all 0.3s ease;
  position: relative;
}

.formComponent input {
  background-color: transparent;
}

input:focus {
  outline: 0px !important;
  appearance: none;
  box-shadow: none !important;
}

.formComponent .textInput input {
  border: 0;
  outline: none;
  padding: 0;
  box-shadow: none;
  padding-top: 14px;
  margin-top: -10px;
}
.formComponent input:focus ~ label {
  left: 0;
  top: -10px;
}
.formComponent input ~ label {
  left: 0;
  font-size: 17px;
  top: 1px;
}
.formComponent input:enabled:focus {
  outline: none;
}
.formComponent input:focus {
  outline: 0;
}

.formComponent .textInput.focus {
  border-color: var(--stepladder-green);
}

.blueBox {
  padding: 25px;
  border-radius: 15px;
  margin-top: 55px;
  cursor: pointer;
  background-color: #f7f7ff;
}
.blueBox.success {
  background: #dffff7;
}
.blueBox.fail {
  background: #ffdfdf;
}
.getStartedForm .headerDescription {
  width: 100%;
}

.textInput .tooltipContainer {
  position: absolute;

  top: 0px;
  right: 15px;
  padding: 0;
  min-width: 0;
  z-index: 25;
  min-height: 0;
}
.textInput .tooltipContainer .pi-info-circle {
  font-size: 15px;
  padding: 10px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: rgb(36, 36, 36);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  right: 22px;
  top: 20px;
  padding: 5px;
  font-weight: 500;
  width: 180px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 30;
}
.tooltip .tooltiptext b {
  font-weight: 600;
  text-decoration: underline;
  text-decoration-style: dotted;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.formComponent.visibleSelect {
  width: 100%;
  /* border-bottom: 1px solid var(--surface-c); */
}
.visibleOption {
  display: inline-block;
  margin-right: 15px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 20px;
  min-width: 60px;
  text-align: center;
  border: 1px solid #ececec;
  margin-bottom: 15px;
  transition: all 0.3s ease;
}
.visibleOption.selected {
  background-color: var(--primary-color);
  color: white;
}
.visibleOptionContainer {
  margin-top: 15px;
}

.flexeven > div {
  flex-grow: 1;
  flex-basis: 0;
}

.flexeven {
  display: flex;
}
.flex {
  display: flex;
}
.congratsQuiz {
  width: 320px;
  margin-top: 10px;
  margin-bottom: 40px;
}
.quizAnswers > div {
  border: 1px solid #dedede;
  padding: 17px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
}
.quizAnswers > div > div:last-child {
  flex-grow: 1;
  margin-top: -2.5px;
}
.quizAnswers {
  margin-top: 20px;
}
.quizAnswers .selected {
  background-color: #f5faff;
}
.helper {
  background: #f0f4ff;
  padding: 15px;
  border-radius: 10px;
  font-size: 13px;
}
.helper i {
  font-size: 13px;
}
.helper {
  margin-top: 25px;
}
.quizNumber {
  display: inline-block;
  padding: 10px;
  background: #f7f7f7;
  border-radius: 10px;
  color: grey;
  margin-bottom: 20px;
}
.loadingOverlay img {
  width: 50px;
}
a {
  text-decoration: none;
  font-weight: 600;
  color: inherit;
}
.waitQuizBullets li {
  margin-bottom: 20px;
}
.waitQuizBullets {
  padding-left: 20px;
}
.investorTitle span {
  color: var(--stepladder-green);
  text-decoration: underline;
}
.investorSubTitle {
  font-weight: 600;
  padding-top: 14px;
}
.tooltipContainer {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.blueBox.investor {
  cursor: initial;
  margin-top: 0;
  margin-bottom: 50px;
  display: block;
}
.blueBox.investor li,
.blueBox.investor ul {
  padding: 0;
  margin: 0;
  margin-block-start: 0px;
}
.blueBox.investor p:first-child {
  margin-top: 0;
}
.blueTooltip {
  background: #0d89ec;
  border-radius: 50%;
  color: white;
}
.blueBox.investor ul {
  list-style: none;
}

.blueBox.investor ul li:before {
  content: "✓ ";
}
.investorSubTitle {
  font-weight: 600;
  margin-bottom: 15px;
}
.blueBox.investor li {
  margin-bottom: 20px;
}
.congratsInvestor {
  text-align: center;
}
.congratsInvestor img {
  width: 120px;
  margin-bottom: 20px;
  border-radius: 50%;
}
.congratsInvestor {
  /* border-bottom: 1px solid #dedede; */
  /* padding-bottom: 50px; */
  margin-bottom: 35px;
}

.terms_summary .p-card-content > div {
  display: inline-block;
  width: 30%;
  margin-bottom: 2em;
}

.terms_summary .p-card-title {
  color: var(--primary-color);
  font-size: 1.3em;
}

.terms_summary .p-card-content > div > div:first-child {
  color: purple;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 0.8em;
}

.terms_summary .p-card-content > div > div:last-child {
  color: var(--primary-color);
  font-size: 1.1em;
  font-weight: 700;
}

.terms_summary .p-card-content > div:nth-child(6),
.terms_summary .p-card-content > div:nth-child(3) {
  width: 18%;
}

.terms_summary .p-card-content > div:nth-child(5),
.terms_summary .p-card-content > div:nth-child(2),
.terms_summary .p-card-content > div:nth-child(1),
.terms_summary .p-card-content > div:nth-child(4) {
  width: 40%;
}

.firstDrawDay {
  color: var(--primary-color);
  width: 100% !important;
  display: block !important;
  margin-bottom: 0 !important;
  padding: 1.5em 0em 0em 0em;
  text-align: center;
  font-weight: 700 !important;
  margin-top: 0em;
  border-top: 1px solid #bebebe;
}
.terms_summary {
  margin-top: 40px;
  margin-bottom: 35px;
}
.agreements .p-inputswitch {
  margin-right: 10px;
}
.agreements > div {
  padding: 20px 0px;
}
.agreements > div:not(:last-child) {
  border-bottom: 1px solid #dedede;
}
.differentCircle {
  text-align: center;
}
.paymentsForm .billing_address {
  margin-bottom: 40px;
}
.paymentsForm .billing_address br {
  display: none;
}
.paymentsForm .billing_address .p-inputswitch {
  float: left;
  margin-top: 0;
  margin-right: 10px;
}
.paymentsForm .billing_address .p-inputswitch,
.paymentsForm .billing_address label {
  vertical-align: middle;
}
.inputSwitch {
  margin-top: 10px;
}
.paymentRedirectMessage {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.paymentRedirectMessage > div {
  width: 180px;
  text-align: center;
  background-color: white;
  border: 1px solid #dedede;
  padding: 20px;
  border-radius: 10px;
}
.platformMobileMenu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.97);
  padding: 20px;
  display: flex;
  z-index: 50;
  text-align: center;
  color: grey;
  justify-content: center;
}
.platformMobileMenu > div div {
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 3px;
}
.platformMobileMenu i {
  font-size: 25px;
}
.platformMobileMenu > div {
  flex: 1 1 0;
}
.platformMobileMenu .active {
  color: #39ad8e;
}
.capitalize {
  text-transform: capitalize;
}
.circleCarousel .add {
  background: var(--primary-color);
  color: white;
  padding: 15px 10px;
}
.circleCarousel {
  white-space: nowrap;
  overflow-x: auto;
  margin-left: -30px;
  width: 100vw;
  padding-left: 30px;
  padding-bottom: 15px;
}

.circleCarousel > div {
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
  margin-right: 15px;
}
.circleCarouselBox {
  width: 60vw;
  height: 40vw;
  position: relative;
  overflow: hidden;
  box-shadow: 0 6px 2px -2px #cccccc;
  z-index: 10;
}
.coverimage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.carouselBoxContent .raiseAmount {
  color: #b2f3e2;
  font-size: 14px;
}
.carouselBoxContent {
  padding: 15px;
  font-weight: 600;
  color: white;
  position: absolute;
  bottom: 0;
  font-size: 19px;
  left: 0;
  width: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.79) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.79) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.79) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}
.carouselBoxLength {
  width: 70%;
  height: 10px;
  border-radius: 15px;
  background-color: white;
  margin-top: 15px;
  overflow: hidden;
}
.carouselBoxLength div {
  height: 100%;
  background-color: var(--stepladder-green);
}
.carouselBoxStatus {
  font-size: 14px;
  right: 10px;
  top: 10px;
  border-radius: 3px;
  padding: 5px;
  position: absolute;
  color: white;
  font-weight: 600;
}
.circleView {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100%;
  background-color: white;
  z-index: 150;
}
.circleView > div {
  overflow-y: scroll;
  height: 100%;
}
.circleView .circleCarouselBox {
  width: 100%;
  margin-right: 0;
  height: 40vh;
  min-height: 40vh;
  margin-top: -40px;
}
.circleView .carouselBoxContent {
  padding: 25px;
  font-size: 28px;
}

.spin {
  animation: spin 0.5s linear infinite;
  display: inline-block;
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.circleViewBack {
  position: relative;
  top: 20px;
  left: 20px;
  padding: 10px 12px;
  z-index: 15;
  background-color: white;
  color: grey;
  border-radius: 50%;
  display: inline-block;
}
.circleViewTabs > div {
  padding: 15px 20px;
}
.circleViewTabs {
  font-size: 19px;
  border-bottom: 1px solid #dedede;
  background-color: white;
  z-index: 10;
}
.circleViewTabs {
  display: flex;
  padding-left: 5px;
  position: sticky;
  top: 0;
}
.circleViewTabs .active {
  color: #39ad8e;
  background-color: #f1f1f1;
}
.circleViewTabContent {
  padding: 25px;
}
.platformTitle {
  font-weight: 700;
  font-size: 12.5px;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 13px;
}
.summaryBoxes .number {
  font-weight: 900;
  font-size: 22px;
}
.summaryBoxes .description {
  font-size: 12.5px;
}
.summaryBoxes {
  text-align: center;
  display: flex;
}
.summaryBoxes.twoPerLine {
  display: block;
}
.summaryBoxes.twoPerLine > div {
  display: inline-block;
  width: 45%;
  margin-bottom: 25px;
  vertical-align: top;
}

.summaryBoxes.twoPerLine > div:nth-child(odd) {
  margin-right: 5%;
}
.summaryBoxes > div, .summaryBoxes > a > div {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px;
}
.summaryBoxes a{
  width:100%
}
.summaryBoxes > div{
  border-radius: 5px;
  flex-grow: 1;
  flex-basis: 0;
}
.summaryBoxes > div:not(:last-child) {
  margin-right: 15px;
}
.platformTitle {
  color: rgb(134, 134, 134);
}
.circleMembers > div:nth-child(odd) {
  margin-right: 10%;
}
.circleMembers > div {
  width: 45%;
  display: inline-block;
  margin-bottom: 15px;
  display: inline-flex;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  padding: 10px;
}
.circleMemberName {
  font-size: 13px;
}
.circleMemberName > div:last-child {
  text-transform: uppercase;
  border-radius: 5px;
  display: inline-block;
  font-size: 11px;
  margin-top: 2px;
  color: grey;
}
.circleMemberName > div:first-child {
  font-weight: 700;
}
.circleMemberPic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}
.circleMemberName > div.drawn {
  color: #3cab8e;
  font-weight: 700;
}
.spotSelection {
  margin-top: 15px;
}
.spotSelection > div {
  background-color: #dedede;
  padding: 10px;
  display: inline-block;
  border-radius: 10px;
  font-size: 13px;
}
.spotSelection .active {
  background-color: var(--primary-color);
  color: white;
}
.chatBox {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  background-color: #efefef;
  display: flex;
}
.chatBox > div:last-child {
  margin-top: 5px;
}

textarea.p-inputtext:enabled:hover {
  border-color: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.chatBox textarea {
  width: 100%;
  padding: 3px;
  background-color: transparent;
  border: 0;
  outline: 0;
}
.chatBox i {
  background: var(--stepladder-green);
  padding: 8px;
  color: white;
  border-radius: 5px;
}
.chatBox > div:first-child {
  flex-grow: 1;
}
.chatMessage {
  padding: 15px 10px;
  background: #efefef;
  font-size: 13.5px;
  border-radius: 10px;
  display: inline-flex;
  width: 280px;
  text-align: left;

  margin-bottom: 15px;
}
.chatMessage .name {
  font-weight: 700;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chatMessage .name > div:first-child {
  flex-grow: 1;
}
.chatMessage .date {
  font-size: 12px;
  color: grey;
}
.chatMessage .message {
  margin-top: 5px;
}
.chatMessage > div:last-child {
  margin-left: 10px;
  flex-grow: 1;
}
.chatMessageContainer {
  padding-bottom: 50px;
}
.individualChatMessage {
  text-align: left;
}
.individualChatMessage.me {
  text-align: right;
}
.individualChatMessage.me .chatMessage {
  background-color: #aeffea;
}
.paymentList > div {
  padding: 10px;
  border: 1px solid #bebebe;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  width: 45%;
  margin-bottom: 15px;
}
.paymentList > div:nth-child(odd) {
  margin-right: 10%;
}
.paymentList i {
  font-size: 40px;
}
.paymentList > div > div:first-child {
  margin-right: 10px;
}
.paymentList .number {
  font-size: 19px;
  font-weight: 700;
}
.paymentList .date {
  color: grey;
}
.paymentList {
  margin-top: 25px;
}
.paymentList > div > div:last-child {
  margin-top: -5px;
}
.categories > div {
  display: inline-flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f9;
  text-align: center;
  margin-right: 15px;
  border-radius: 10px;
  cursor: pointer;
  white-space: normal;
  max-width: 100%;
  vertical-align: middle;
  font-size: 13px;
}
.categories > div > div {
  padding: 0px 10px;
}
.categories {
  white-space: nowrap;
  overflow-x: auto;
  margin-left: -30px;
  width: 100vw;
  padding-left: 30px;
  margin-top: 35px;
  margin-bottom: 35px;
}
.categories i {
  font-size: 24px;
}
.articleListClient > div {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 20px;
  cursor: pointer;
}
.articleListClient > div > div:last-child {
  flex-grow: 1;

  margin-left: 10px;
}
.articleListClient .coverimage {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  min-width: 70px;
}
.articleListClient {
  padding-bottom: 50px;
}
.articleListClient .title {
  font-weight: 700;
  font-size: 17px;
}
.articleListClient .description {
  color: grey;
  font-size: 13px;
}
.categories .active {
  background-color: var(--stepladder-green);
  color: white !important;
  font-weight: 600;
}
.overlayTransparent {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}
.overlayTransparent > div {
  background-color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.overlayTransparent > div > div {
  padding: 15px;
}
.overlayTransparent .greyBar > div {
  background-color: #dedede;
  border-radius: 20px;
  height: 5px;
  width: 50px;
  display: inline-block;
}
.overlayTransparent .greyBar {
  text-align: center;
}
.overlayTransparent h2 {
  font-size: 20px;
  text-align: center;
}
.overlayTransparent > div:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}
.overlayTransparent .description {
  color: grey;
  font-size: 13px;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}
.overlayTransparent > div > div:not(.noSeparator) {
  border-bottom: 1px solid rgb(206, 206, 206);
}
.introRows .title {
  font-weight: 700;
}
.introRows > div {
  display: flex;
  margin-bottom: 40px;
}
.introRows i {
  margin-right: 15px;
  font-size: 25px;
  color: #3cab8e;
}
.introRows .description {
  color: grey;
  font-size: 13.5px;
  margin-top: 5px;
}
.introRows {
  margin-top: 30px;
}
.overlayTransparent .title {
  padding-top: 0;
}
.overlayTransparent .greyBar {
  padding-top: 10px;
}
.xScrollSelect {
  overflow-x: auto;
  white-space: nowrap;
  font-size: 13px;
}

.xScrollSelect > div {
  display: inline-block;
  margin-right: 33px;
  width: 75px;
  text-align: center;
  position: relative;
}
.xScrollSelect .coverimage {
  height: 75px;
  border-radius: 15px;
  margin-bottom: 10px;
}
.xScrollSelect .title {
  white-space: normal;
  max-width: 100%;
}
.xScrollSelect .active {
  color: #3cab8e;
}
.xScrollSelect i {
  background-color: #3cab8e;
  color: white;
  padding: 4px 6px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
}
.xScrollSelect .active .coverimage {
  border: 4px solid #3cab8e;
}
.customerInfoBox {
  background-color: #daeeff;
  padding: 10px;
  margin-top: 25px;
  border-radius: 10px;
}
.chatMessage .coverimage {
  width: 40px;
  height: 40px;
  background-color: #dedede;
  border-radius: 5px;
}
.article,
.articleHelp {
  max-width: 100%;
  padding: 20px;
}
.article img {
  width: 100%;
}
.article ul,
ol {
  padding-left: 20px;
}
.article li {
  margin-bottom: 10px;
}
.articleHelp {
  border-top: 1px solid #bebebe;
}
.article .ql-video {
  width: 100%;
  height: 220px;
}
.skeleton > div {
  background: #dedede;
}
.desktopMenu > div > div {
  background-color: #3c4b6f;
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
}
.desktopMenu i {
  margin-right: 5px;
}
.desktopMenu > div > div:hover {
  opacity: 0.8;
  cursor: pointer;
}
.desktopMenu {
  margin-top: 50px;
}
.desktopMenu .active {
  background-color: #47e7bc;
  color: var(--primary-color);
  font-weight: 600;
}

.feedComponentContainer .title {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
}
.feedComponentContainer {
  margin-top: 55px;
  margin-bottom: 20px;
}
.cursorPointer {
  cursor: pointer;
}

.shareButtons {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 15px;
}
.shareButtons img {
  height: 35px;
  vertical-align: middle;
  margin-top: -10px;
}
.shareButtons > div:first-child {
  flex-grow: 1;
}
.shareButtons .copy {
  padding: 4px;
  background-color: #e1e1ff;
  font-size: 22px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 5px;
}
.shareButtons input {
  width: 100%;
}
.feedComponentContainer .content {
  background: #f2f2fb;
  padding: 15px;
  border-radius: 10px;
}
.desktopTopMenu {
  color: white;
  display: inline-flex;
  width: 100%;
  align-items: center;
  color: white;
}
.desktopTopMenu > div:first-child {
  flex-grow: 1;
}
.desktopTopMenu .flex > div {
  margin-left: 35px;
}
.desktopTopMenuContainer {
  text-align: center;
  background-color: var(--primary-color);
  z-index: 1;
  box-shadow: 0 0 4px #1a2b589e;
  padding: 0.8em;
}
.desktopTopMenuContainer > div,
.platformContent > div {
  text-align: left;
  max-width: 1066px;
  width: 1066px;
}
.desktopTopMenu i {
  margin-right: 5px;
}
.desktopTopMenu span,
.desktopTopMenu i {
  vertical-align: middle;
}
.desktopTopMenu .menuItem.active {
  border-bottom: var(--stepladder-green);
  color: var(--stepladder-green);
  opacity: 1;
}
.desktopTopMenu .menuItem {
  opacity: 0.7;
  cursor: pointer;
}
.desktopTopMenu .menuItem:hover {
  opacity: 1;
}
.banner {
  position: relative;
  height: 250px;
  background-color: var(--primary-color);
}
.banner .coverimage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
}
.platformContent {
  text-align: center;
  position: relative;
}
.overlayTransparent .p-inputtext {
  width: 100%;
  padding: 15px;
  margin-top: 5px;
}
.article.marketplace > p:first-child a {
  background-color: var(--primary-color);
  color: white;
  padding: 10px;
  border-radius: 5px;
}
.drawDayStoryContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}
.drawDayReveal {
  width: 100%;
  height: 100%;
  background-color: var(--stepladder-green);
}
.collapseit {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: yellow;
}
.drawDayReveal {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-size: 25px;
}
.awardAmount {
  font-size: 75px;
  font-weight: 600;
  color: white;

  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}
.seeMoreButton{
  position:fixed;
  bottom: 0;
  width:100%;
  left:0;
  max-width: 100%;
  padding:30px
}
.drawDayStoryContainer.desktop{
  display: flex;
  vertical-align: middle;
  justify-content: center;
  background: rgba(0, 0, 0, 0.9);
  cursor:pointer
}
.drawDayStoryContainer.desktop .seeMoreButton{
  text-align: center;
  margin-bottom: 50px;
}
.homeCircleWarning{
  background-color: #acf7e3;
    padding: 10px;
    border-radius: 10px;
    margin-bottom:25px
}
.shareButtons > div:last-child{
  min-width: 105px;
}
.creditBuilderWarning{
  background: #e7f4ff;
    padding: 10px;
    border-radius: 10px;
}
.ambassadorCirclePic{
  width:50px;
  height:50px;
  border-radius: 50%;
  position: absolute;
  bottom:15px;
  right:15px;
  border: 2px solid white;
  background-color: white;
}
.listSelection > div{
  border: 1px solid #bebebe;
  padding:10px;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.listSelection{
  margin-top:20px
}
.readonlylisten input[readonly]{
  background-color: #dedede;
}
.offersPopup .coverimage{
  width:140px;
  height:140px;
  border:1px solid #bebebe;
  margin-bottom:10px;
  border-radius:10px;
}
.offersPopup > div:first-child{
  
  margin-right:15px;
}
.offersPopup > div{
  
  
  display: inline-block;
}
.offersPopup{
  text-align: center;
  background-color: white;
  margin-top:15px;
}
.offersPopupContainer{
  background-color: white;
  padding:15px
}
.firstStepsOptions button{
  margin-bottom:15px
}
.vouchers-dialog .p-dialog-header{
  display: none;
}
.vouchers{
  padding: 0;
}
.vouchers > div{
  padding:20px
}
.offerlist > div{
  border-bottom:1px solid #bebebe;
  padding:10px 20px;
}
.bankconfirm{
  width:50px;
  height:50px;
  margin-right:10px
}
.yesno > div{
    display: inline-block;
    padding:8px 14px;
    border-radius: 5px;
    border:1px solid grey;
    margin-right: 10px;
}
.investOption{
  border:1px solid #bebebe;
  padding:20px;
  border-radius: 5px;
  margin-bottom: 25px;
}
.investOption button{
  margin-top: 10px;
}
.highlightClickable{
  width:42vw;
  height:42vw;
  overflow: hidden;
  border-radius: 10px;
}
div > .highlightClickable:first-child{
  margin-right:6vw
}
.highlightClickable .darkenOverlay{
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color: rgba(0, 0, 0, 0.35);
}
.highlightClickable .title{
  color:white;
  font-weight: 600;
  font-size: 18px;
}
.highlightClickable .description{
  color:white;
  font-size:14px
}
.highlightClickable .textContainer{
  padding:5px 15px 15px 10px;
  position: absolute;
  bottom:0;
  left:0;
  width:100%;
}
.highlightOptionContainer{
  border:1px solid #bebebe;
  padding:10px;
  border-radius: 10px;
  margin-bottom:15px
}
.addcircledialog .p-dialog-header{
  display: block;
}
.addcircledialog .p-dialog-content{
  padding:20px
}
.addcircledialog .p-dialog-header-icons{
  display: none;
}

.ambassadorWarning {
  /* background-color: #51e9c161; */
  background-color: #51b1e961;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.quizPageLayout{
  height:100%;
  display:flex;
  flex-direction: column;
}
.quizPageLayout > div:first-child{
  flex-grow: 1;
  overflow-y:auto
}
/* .circleImage{
  width:60%;
  margin-left:15%;
  margin-bottom:10vw
} */
.quizTitle{
  margin-bottom:40px
}
.infoSlab img{
  width:77px;
  margin-right:12px
}
.infoSlab{
  margin-top:25px
}
div > .infoSlab:not(:last-child){
  /* border-bottom:1px solid #bebebe; */
  border-bottom: 1px solid #BA8CEB;
  padding-bottom:15px
}
/* new design */
.infoSlab.new{
  margin-top:5px!important;
}
div > .infoSlab.new:not(:last-child){
  padding-bottom:5px!important;
}
.quizAnswer{
  border:1px solid #bebebe;
  padding:20px;
  border-radius: 5px;
  margin-bottom:20px;
}
.quizAnswer .p-checkbox{
  margin-right:15px;
}
.quizAnswer .p-checkbox, .quizAnswer .p-checkbox-box{
  width:30px;
  height:30px;
  vertical-align: middle;
}
.answerinfo .correct{
    color:green
}
.answerinfo .incorrect{
  color:red
}
.answerinfo span{
  font-weight: 600;
}
.quizAnswer.correct .p-checkbox .p-checkbox-box.p-highlight {
  border-color: green;
  background: green;
}
.quizAnswer.correct{
  border-color:green;
  color:green
}
.quizAnswer.incorrect .p-checkbox .p-checkbox-box.p-highlight {
  border-color: red;
  background: red
}
.quizAnswer.incorrect{
  border-color:red;
  color:red
}
.variant.centerMiddle .amountCharged{
  display: none;
}
.variant.centerMiddle button{
  margin-bottom:-20px
}


.onboarding-subtitle {
  font-size: 14px;
  margin-top: 15px;
  max-width: 500px;
  margin-bottom: 15px;
}

/* Only aplied for deskotp */
@media only screen and (min-width: 600px) {
  div > .formButtonContainer {
    display: block;
    margin-top: 20px;
 }
  .drawDayStoryContainer{
    display: flex;
    vertical-align: middle;
    justify-content: center;
    background: rgba(0, 0, 0, 0.9);
    cursor:pointer
  }
  .drawDayStoryContainer .seeMoreButton{
    text-align: center;
    margin-bottom: 50px;
  }
  .onboardingContainer {
    background: none!important;
  }
  .min-height-800-desktop {
    min-height: 800px;
  }

  .page-wrapper-header {
    padding-top: 3em;
  }
  .onboarding-subtitle {
    color: grey;
  }
}

.main-color {
  color: var(--primary-color)!important;
}

.grey-color {
  color: grey!important;
}

.min-height-100 {
  min-height: 100px!important;
}


.editambassadordialog{
  height: 75vh;
}
.editambassadordialog .p-dialog-content{
  width: 62vw;
  height: 75vh;
  overflow-y: auto;
}
.editambassadordialog .p-dialog-header-icons{
  /* display: none; */
}

.flex-center-bold {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.disclaimer_text{
  text-decoration: underline;
  font-weight: bold;
  margin-top: 0px;
  padding-top: 5px;
  padding-left: 20px;
  padding-bottom: 30px;
}

.blueTooltip .pi-info:before {
  font-size: 80%;
}

.text-shadow {
  text-shadow: 1px 0 0 #e6e6e600, 0 -1px 0 #e6e6e600, 0 1px 0 #00000075, -1px 0 0 #e6e6e600;
}

.bg-stepladder-green {
  background-color: var(--stepladder-green);
}
