.platform_section_separator {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  width: 85%;
  margin-left: 7.5%;
  border: 1px solid #efefef;
}
.platform_menu {
  display: flex;
  border-top: 1px solid #bebebe;
  font-size: 0.9em;
  color: var(--grey-wording);
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
}
.platform_menu i {
  font-size: 1.75em;
}
.platform_menu > div {
  flex: 1;
  text-align: center;
  padding: 1em 0em;
}

/* .leftNav */

.leftNav {
  box-shadow: 0px 0px 5px 0px #c3c3c3;
  border-right: none!important;
  /* width: 14em; */
  border-right: 1px solid #bebebe;
  display: block;
  transition: width .3s;
  height:100vh
}

.leftNav .tieredmenu {
  border: 0!important;
  border-radius: 0!important;
  border-top: 4px solid #efefef9e!important;
}

.mini-menu.leftNav {
  width: 60px;
}

.admin-logo {
  width: 12em;
  margin: 1em;
}

.mini-menu .admin-logo {
  width: 2em;
  margin: 0.6em;
}

.mini-menu .p-menuitem-icon.pi.pi-fw.pi-bars:before {
  margin-right: -8px;
}
.mini-menu .p-menuitem-icon.pi.pi-fw.pi-power-off:before {
  margin-right: -8px;
}

.mini-menu .logo-wrapper {
  width: 60px;
  height: 60px;
}

.mini-menu.leftNav {
  width: 60px;
}

.mini-menu .p-menuitem-text {
  display: none;

  /* visibility: hidden;
  transition: visibility .3s; */
}

.mini-menu .p-menuitem-text {
  display: none;

  /* visibility: hidden;
  transition: visibility .3s; */
}
/* .leftNav */

.row-highlight{
  background-color: #c6fff1 !important
}

.platform_menu .active {
  color: var(--primary-color);
  font-weight: 700;
}

.home_header {
  background-color: var(--primary-color);
  color: white;
  padding-top: 1em;
  padding-bottom: 5em;
  position: relative;
  overflow: hidden;
}


.home_header h1 {
  font-size: 2.3em;
}

.individual_circle_container {
  width: 55%;
  margin-left: 22.5%;
}

.individual_circle_quick_stats {
  display: flex;
  margin-top: 2em;
}

.individual_circle_quick_stats > div {
  text-align: center;
  flex: 1 1 0;
}

.individual_circle_quick_stats > div > div:first-child {
  font-weight: 700;
  color: var(--grey-wording);
  font-size: 1.17em;
}
.individual_circle_quick_stats > div > div:last-child {
  font-weight: 800;
  font-size: 2em;
}

.individual_circle_people > div > div:first-child {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

.individual_circle_people > div > div:last-child {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.individual_circle_people {
  overflow-x: auto;
  white-space: nowrap;
}

.individual_circle_people > div {
  display: inline-block;
  margin-right: 1.5em;
  text-align: center;
  vertical-align: middle;
  width: 5em;
}

.individual_circle_details > div {
  border: 1px solid #e0e0e0;
  padding: 1em;
  border-radius: 0.5em;
  font-weight: 700;
  display: flex;
  margin-bottom: 1em;
}
.individual_circle_details > div > div:first-child {
  color: var(--grey-wording);
  flex-grow: 1;
}
.last_winner_pic {
  width: 5em;
  margin-right: 0.75em;
  height: 5em;
  background-image: url(https://www.flaticon.com/svg/static/icons/svg/599/599305.svg);
}

.last_winner {
  display: flex;
  align-items: center;
}
.last_winner > div:last-child > div:first-child {
  font-weight: 800;
  font-size: 1.2em;
}
.last_winner_action {
  background-color: var(--primary-color);
  color: white;
  padding: 0.5em;
  border-radius: 0.5em;
  margin-top: 0.25em;
}

.desktop_panel_buttons {
  flex-flow: column;
  display: inline-flex;
}

.desktop_panel_buttons > div.menu-item {
  padding: 0.75em;
  background: #44547d;
  display: inline-block;
  border-radius: 0.5em;
  margin-bottom: 1em;
  cursor: pointer;
  min-width: 120px;
}
.desktop_panel_buttons > div:hover {
  background-color: #3a496e;
}
.desktop_panel_buttons i {
  margin-right: 0.5em;
  vertical-align: middle;
}
.desktop_panel_buttons span {
  vertical-align: middle;
}
.desktop_panel_buttons {
  margin-top: 4em;
}
h1.platform {
  margin-top: 0;
}
.desktop_panel_buttons .selected {
  background-color: #212a40;
}
/* table */

.blue-table.p-datatable .p-datatable-thead > tr > th {
  border-bottom-width: 0px;
  color: #495057;
  background: #e7f3ff!important;
}

.table-rounded.p-datatable .p-datatable-thead > tr > th {
  border-bottom-width: 0px;
  color: #495057;
  background: #e7eaff;
  text-align: center;
  vertical-align: middle;
}

.table-rounded.p-datatable .p-datatable-thead > tr > th:first-child { 
  border-radius: 0.25rem 0 0  0.25rem;
  box-shadow: -2px 2px 4px rgb(126 142 177 / 12%);
}
.table-rounded.p-datatable .p-datatable-thead > tr > th:last-child {
  border-radius: 0 0.25rem 0.25rem 0;
  box-shadow: 2px 2px 4px rgb(126 142 177 / 12%);
}

.table-rounded.p-datatable .p-datatable-thead > tr > th:not(:first-child):not(:last-child) {
  box-shadow: -1px 6px 4px -4px rgb(126 142 177 / 12%);
}

.table-rounded.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #d7dbf5;
  color: #495057;
}

/* .table-rounded .p-datatable-tbody > tr {
  box-shadow: 0px 2px 4px rgb(126 142 177 / 12%);
  transform: scale(1);
} */

.table-rounded table {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.table-rounded .p-datatable-tbody > tr td:not(:first-child):not(:last-child) {
  box-shadow: -1px 6px 4px -4px rgb(126 142 177 / 12%);
}

.table-rounded  .p-datatable-tbody > tr > td {
  text-align: center;
  vertical-align: middle;
}

.table-rounded .p-datatable-tbody > tr td:first-child { 
  border-radius: 0.25rem 0 0 0.25rem;
  box-shadow: -2px 2px 4px rgb(126 142 177 / 12%);
}
.table-rounded .p-datatable-tbody > tr td:last-child {
  border-radius: 0 0.25rem 0.25rem 0;
  box-shadow: 2px 2px 4px rgb(126 142 177 / 12%);
}

.table-rounded .p-datatable-header {
  border-radius: .25rem;
  border: none;
  box-shadow: 0px 0.3rem 1rem 0px rgb(56 65 74 / 15%);
  background: white;
}

.table-rounded .p-paginator{
  box-shadow: 0px 0.3rem 1rem 0px rgb(56 65 74 / 15%);
  border-width: 0;
  border-radius: 0.5rem;
}

.table-rounded input[type=search]{
  width: 100%;
  font-size: 14px !important;
  background: #eaeaec;
  border: 1px solid #eaeaec;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 0px 4px 0px 40px;
  height: 36px;
  font-weight: 500;
}

.no-shadow-table .p-datatable-header,
.no-shadow-table .p-datatable-tbody > tr td,
.no-shadow-table .p-datatable-thead > tr > th{
  box-shadow: none!important;
}

.p-datatable-loading-overlay.p-component-overlay{
  background-color: rgb(236 253 255 / 40%);
}

.p-datatable-loading-icon.pi-spin.pi.pi-spinner {
  color: #2196f3;
}

/* table end */

.admin-login.p-card  {
  border-radius: 0.5em;
  padding: 1em;
  background: #e4f0ff;
  box-shadow: 0px 0.3rem 1rem 0px rgb(56 65 74 / 15%);
  border: 1px solid rgb(179 215 245 / 30%);
}

.admin-login .p-card-title {
  display: flex;
  justify-content: center;
  color: #6c757d;
}

.loc-title {
  color: var(--grey-wording);
}

.loc-amount {
  font-size: 1.5rem;
  /* color: var(--primary-color); */
  color: #00c591;
}

.p-dialog-content {
  border-radius: 0px 0px 8px 8px;
}
.p-dialog {
  border-radius: 8px;
  overflow: auto;
}
.p-dialog .p-dialog-header {
  border-radius: 8px 8px 0px 0px;
}

.title-card {
  box-shadow: var(--light-shadow);
}

.title-card.p-card .p-card-content {
  padding: 0;
}

.warning-bg {
  background-color: #fffb8a!important;
}

.p-tag.p-tag-success {
  background-color: #009688;
}

.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
  padding-left: 10px!important;
  padding-right: 10px!important;
}


.ag-theme-alpine .ag-root-wrapper {
  border-radius: 8px;
  box-shadow: 0px 0px 5px #c3c3c3;
  border: none!important;
}

.ag-row-selected .spine {
  background-color: rgba(33, 150, 243, 0.3);
  background-color: var(--ag-selected-row-background-color, rgba(33, 150, 243, 0.3));
}


.ag-row-selected .future-period {
  background-color: rgba(33, 150, 243, 0.3);
  background-color: var(--ag-selected-row-background-color, rgba(33, 150, 243, 0.3));
}

.p-badge.p-badge-success {
  color: #ffffff;
  background-color: #00c591;
}

.text-xxs{
  font-size: xx-small;
}

.text-xs{
  font-size: x-small;
}

.default-bg {
  background: #f2f2f7!important;
}

@media only screen and (min-width: 600px) {
  .individual_circle_people::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }

  /* Track */
  .individual_circle_people::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 255, 255);
    border-radius: 10px;
  }

  .individual_circle_people {
    padding-bottom: 3em;
  }

  /* Handle */
  .individual_circle_people::-webkit-scrollbar-thumb {
    background: #bebebe;
    border-radius: 10px;
  }
  .platform_circle_membership {
    padding-left: 7em;
  }
  .platform_section h3 {
    font-size: 1.5em;
  }
  .platform_circle_flex_desktop_only {
    display: flex;
    align-items: center;
  }
  .platform_circle_flex_desktop_only .individual_circle_container {
    width: 10em;
    margin-left: 0em;
  }
  .platform_section_separator {
    margin-left: 7em;
    width: 30em;
  }
  .platform_circle_flex_desktop_only .individual_circle_quick_stats {
    flex-grow: 1;
  }
  .platform_circle_membership.title {
    padding-left: 4.5em;
  }
}

@media only screen and (max-width: 600px) {
  .main .p-tabview.help_panel {
    padding: 0;
  }
  .platform_circle_membership {
    padding-left: 1.5em;
  }
}

.home_circle_container {
  width: 4.5em;
  margin-right: 1em;
}
.circle_list > div {
  border-radius: 0.5em;
  padding: 1em;
}
.circle_list > div {
  margin-bottom: 2em;
  cursor: pointer;
}
.circle_list > div:hover {
  opacity: 0.8;
}

.platform_section .onboarding_section {
  padding: 1.5em 0em;
}

.platform_help_section ol {
  padding: 0em 1em;
}

.platform_help_section {
  border-bottom: 1px solid #bebebe;
}

.help_panel .p-tabview-nav {
  padding-left: 1em;
}

.help_panel .p-tabview-panels {
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 9em;
}

.discount_image {
  max-height: 7em;
}

.settings_info {
  border: 1px solid #bebebe;
  display: flex;
  align-items: center;
  border-radius: 0.5em;
  margin-bottom: 1em;
  overflow: hidden;
  font-size: 0.9em;
}

.settings_info > div:first-child {
  background-color: #dedede;
  padding: 0.75em;
  font-weight: 700;
}
.settings_info > div:last-child {
  padding: 0em 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.awarded_message {
  padding-top: 2em;
  padding-bottom: 2em;
  border-bottom: 2px solid var(--stepladder-green);
  margin-bottom: 3em;
}
.seedrsDialog .p-dialog-content{
  padding:0
}
.seedrsDialog .p-dialog-header{
  display: none;
}
.seedrsDialog i{
  position: absolute;
  background: black;
  color: white;
  padding: 15px;
  font-size: 18px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  cursor:pointer;
  border: 2px solid white;
}
.drawdaytable .p-datatable-tbody > tr > td:first-child{
  width:100px
}
.drawdaytable table{
  table-layout: auto;
}

.drawDayGeneratorInstructions{
  display:flex;
  margin-bottom: 1.5em;
  margin-top:1em
}
.drawDayGeneratorInstructions > div{
  flex: 1;
}

.drawDayGeneratorInstructions > div:not(:last-child){
  margin-right:0.8em
}

.drawDayGeneratorInstructions .disable{
  opacity:0.5
}

.drawDayGeneratorInstructions .disable button{
  pointer-events: none;
}

.adminPortalFirstCreditCheckQuickFacts > div{
  display:inline-block;
  margin-right:0.5em;
  border-radius:0.5em;
  background-color:#e5eaff;
  padding:0.3em;
  cursor:pointer
}

.adminPortalFirstCreditCheckQuickFacts{
  margin-bottom: 3em;
}

.adminSecondCreditCheckInfo {
  display:flex
}

.adminSecondCreditCheckInfo > div{
  flex:1
}

.adminSecondCreditCheckInfo > div:not(:last-child){
  margin-right:1em
}

.adminSecondCreditCheckInfo .p-card-title{
  font-size:1.2em;
  margin-bottom:0em
}

.adminSecondCreditCheckInfo > div .p-card-content{
  padding-top:0.2em
}

.countdownToDrawDayBox{
  background-color: var(--stepladder-green);
  padding:15px;
  margin-bottom:35px;
  border-radius: 7px;
  margin-top:20px
}

.countdownToDrawDayBox .title{
  font-weight: 700;
  margin-bottom:10px
}

.countdownToDrawDayBox .number{
  font-weight: 700;
  font-size:32px;
}

.countdownToDrawDayBox img{
  position: absolute;
  top:0;
  right:0;
  height:100%
}

.drawDayWarningBox{
  background-color:#fff1a4;
  margin-bottom:40px;
  padding:20px
}

.drawDayButtons > div{
  pointer-events:none;
  opacity:0.5;
  width:350px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-right:70px;
}

.drawDayButtons > div.active{
  pointer-events: all;
  opacity:1;
  color:var(--primary-color) !important
}

.drawDayButtons {
  display:flex
}
.platformWarning{
  background-color:rgb(255, 239, 212);
  padding:15px;
  border-radius: 10px;
  margin-top:20px;
  margin-bottom: 20px;
}
.articleSelections div{
  /* border:1px solid #bebebe; */
  padding:10px;
  border-radius: 4px;
  display:inline-block;
  margin-right: 15px;
  cursor: pointer;
}
.articleSelections{
  margin-bottom:20px
}
.articleSelections div.active{
  background-color: var(--primary-color);
  color:white !important;
  font-weight: 700;
}
.articleDialog .p-dialog-content{
  flex-grow: 1;
}
.articleSelections div:hover{
  opacity: 0.5;
}
.articleList li{
  padding:10px;
  /* border:1px solid #bebebe; */
  width:80%;
  /* margin-top:8px */
}
.articleList li:hover{
  opacity:0.5;
  cursor:pointer
}
.articleList{
  list-style-type:none;
  margin: 0;
  padding:0;
}
.articleList div{
  display: inline-block;
}
.articleList i{
  cursor:move
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  font-weight: 700;
  color: #5a5f62;
}

.select-buttons-sm{
  height: 35px;
}

.select-buttons-sm .p-button {
  border-width: 2px!important;
}

.chat-dialog.p-datascroller.p-datascroller-inline .p-datascroller-list > li {
    border: none!important;
    /* border-width: 0 0 1px 0; */
}

textarea.message-chat.p-inputtext:enabled {
  border: 2px solid var(--stepladder-green)!important;
}

.selected-circle-chip {
  padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #E3F2FD;
    color: #495057;
    border-radius: 3px;
}
.inplace-form 
.p-inplace-content{
  height: 34px!important;
}

.with-footer .p-dialog-content {
  border-radius: 0;
}