@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap");

h1 {
  margin-bottom: 0;
  font-size: 2rem;
}



:root {
  --primary-color: #2f3c5b;
  --stepladder-green: #51e9c1;
  --grey-wording: grey;
  --light-shadow: 0px 0.3rem 1rem 0px rgb(56 65 74 / 15%);
  --neumorph-shadow: 12px 12px 24px 0 rgb(0 0 0 / 20%), -12px -12px 24px 0 rgb(255 255 255 / 50%);
  --stepladder-purple: #d9c8e7;
  --text-color-secondary: #6c757d;
}
.boxShadow {
  -webkit-box-shadow: 0px 0px 10px 0px rgb(158 158 158 / 75%);
  -moz-box-shadow: 0px 0px 10px 0px rgb(158 158 158 / 75%);
  box-shadow: 0px 0px 10px 0px rgb(158 158 158 / 75%);
}

.sidebar {
  background-color: var(--primary-color);
  width: 40%;
  max-width: 40%;
  min-width: 40%;
  color: white;
  text-align: center;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.dark-bg {
  background-color: var(--primary-color) !important;
}

.darker-bg {
  background: #2c354a;
}

.transparent-bg {
  background: transparent!important;
}

.p-button {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  border-width: 3px !important;
  border-radius: 6px;
  font-weight: 700;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: var(--primary-color);
}

.p-button.p-button-outlined:enabled:hover {
  color: #171e2d;
}

.p-button:enabled:hover {
  background-color: #171e2d;
  border-color: #171e2d;
}

select {
  color: inherit;
}

.flexeven > div {
  flex-grow: 1;
  flex-basis: 0;
}

.flexeven {
  display: flex;
}

.pi-spin {
  animation: fa-spin 0.75s infinite linear;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--stepladder-green);
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background: #47c3a3;
}

.flex {
  display: flex;
  align-items: center;
}
.flexgrow {
  flex-grow: 1;
}

.main,
.sidebar {
  padding-top: 5em;
  overflow-y: auto;
}

.sidebar,
.height-100 {
  height: 100%;
  max-height: 100%;
}

.main > div:first-child > div {
  padding-left: 7em;
  padding-right: 17em;
}

.main {
  padding-bottom: 3em;
}

.sidebar > div {
  padding-left: 2em;
  padding-right: 2em;
  text-align: center;
}

.onboarding_section.slider > div:last-child > div:first-child {
  margin-right: 0.5em;
  font-weight: 600;
  font-size: 1.1em;
}

div {
  position: relative;
}

.mobile_menu img {
  width: 3.5em;
  margin-left: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.mobile_menu i {
  float: right;
  padding: 1.3em;
  font-size: 1.5em;
  color: #585858;
}

.mobile_menu {
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 5;
  position: sticky;
  top: 0;
  height: 10%;
  z-index: 25;
  min-height: 80px;
}

.p-datatable .p-datatable-tbody > tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile_menu.dark_menu {
  background-color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  overflow: hidden;
  margin-bottom: -1px;
}

.mobile_menu.dark_menu i,
.text-white {
  color: white!important;
}

@media only screen and (min-height: 850px) and (min-width: 600px) {
  div.main {
    padding-top: 8em;
  }
}

@media only screen and (min-width: 600px) {
  .main {
    z-index: 50;
  }
  .desktop_only {
    display: block;
  }
  .initial_loader {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }
  .nice_scroll_x::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  .nice_scroll_x::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .nice_scroll_x::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  .nice_scroll_x::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  div div.mobile_only {
    display: none;
  }
  .mobile_only {
    display: none;
  }
  .outer_container {
    display: flex;
  }
  .main {
    flex-grow: 1;
  }
  .drawDayContainer > div {
    margin: -20px;
  }
  
  .partner-popup {

    background-position: center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
  }
}

a {
  font-weight: 700;
  text-decoration: none;
  color: inherit;
}

select {
  outline: none;
}

.boldRow {
  font-weight: bold !important;
}

.greenRow {
  background-color: #d1f8ee !important;
  font-weight: bold;
}

/* only mobile */

@media only screen and (max-width: 599px) {
  .desktop_only {
    display: none;
  }
  .main > div:first-child > div {
    padding-left: 2em;
    padding-right: 2em;
  }
  .main > div:first-child {
    flex-grow: 1;
    overflow-y: overlay;
  }
  .main {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    overflow-y: hidden;
    min-height: 88%;
    display: flex;
    flex-direction: column;
  }
  /* .outer_container{
        display:flex;
        flex-direction: column;
    } */

  .main.onboarding > div:first-child::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
  }

  .main.onboarding > div:first-child::-webkit-scrollbar {
    width: 25px;
    background-color: white;
  }

  .main.onboarding > div:first-child::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    width: 10px;
    border: 9px solid white;
    border-radius: 10px;
    background-color: #f1efef;
  }
  .bg-stepladder-sm {
    background: var(--stepladder-green);
  }

  .proportunity.partner-popup {
    background-size: 250%!important;
  }

  .proportunity .padding-top-md ,
  .hl .padding-top-md {
    padding-top: 50px!important;
  }

  .partner-popup {
    background-position: 65% 100%!important;
    background-size: 300%!important;
  }
  .shortTitle {
    width: 300px
  }
}
@media only screen and (min-width:376px) and (max-width: 600px) {

  .proportunity.partner-popup {
    background-size: 300%!important;
  }

  .hl.partner-popup {
    background-size: 350%!important;
  }

  .partner-popup {
    background-size: 400%!important;
  }

  .padding-top-md-100 {
    padding-top: 100px;
  }
  .padding-top-md {
    padding-top: 50px;
  }

  .proportunity .padding-top-md,
  .hl .padding-top-md  {
    padding-top: 150px!important;
  }
}

h3 .bigger {
  font-size: 1.1rem;
}

.overlay_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 5;
  padding-top: 4em;
  font-size: 1.25em;
  overflow-y: auto;
}
.overlay_menu > div {
  padding: 2.5em 2em;
  border-bottom: 1px solid #bebebe;
}
.overlay_menu i {
  font-size: 1.2em;
  vertical-align: middle;
  margin-right: 0.3em;
}

.pointer {
  cursor: pointer
}

.text-center {
  text-align: center;
}

.hidden {
  display:none;
}

.spots {
  padding: 1em;
  background: #f7f7f7;
  border-radius: 4px;
  margin-top: 1em;
}


.paidin {
  border-radius: 8px;
  padding: 0.5em;
  background: #e0e0e0;
  font-weight: 700;
  width: 100%;
  height: 40px;
  line-height: 24px;
  cursor: pointer;
}

.pading-top {
  padding-top: 1em;
}

.paidin.selected {
  box-shadow: inset 0px 0px 0px 2px #000;
}

.paidin > div {
  float: right;
  margin-right: 5px;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.container>div {
  flex: 0 45%;
  margin: 5px;
}


.spotnumber {
  color: var(--text-color-secondary);
}




.active {
  color: #47c3a3!important;
}

.failed {
  color: rgb(227 15 15)!important;
}

.not-exist {
  color: rgb(168, 168, 168)!important;
}

.warning {
  color: rgb(248, 229, 58)!important;
}

.pending {
  color: rgb(9, 98, 158)!important;
}

.finished {
  color: #555!important;
}

.prop h4, .gray-text {
  color: gray;
}

.prop h4 span {
  color: initial;
  font-weight: 700;
}

.prop h4 {
  margin: 1em 0;
  font-weight: 400;
}

.current {
  background: #47c3a3;
  /* border: 12px solid #000; */
}

.spot {
  font-weight: bold;
}

.circle-tracker .p-dropdown {
  min-width: 7em;
}

.circle-tracker .p-dropdown-label {
  font-weight: 700;
  font-size: xx-large;
}

.circle-tracker .p-dropdown-label.p-inputtext {
  padding-top: 0px;
  padding-right: 0rem;
  padding-bottom: 0px;
  padding-left: 0.8rem;
}

.circle-tracker h1 {
  margin-top: 0;
}

.center {
  display: flex;
  justify-content: center;
}

.center-img,
.center-el {
  display:block;
    margin:auto;
}

.center-el-vertically {
  display: block;
  margin: auto 0;
}

.width-100 {
  width: 100% !important;
}

.width-4em {
  width: 4em !important;
}

.width-6em {
  width: 6em !important;
}

.width-8em {
  width: 8em;
}

.width-10em {
  width: 10em;
}

.width-100-child > div {
  width: 100%;
}

.tooltipBtn.p-button {
  background-color: #2196F3;
  /* border-color: var(--primary-color); */
  border-width: 0px !important;
  border-radius: 30px;
  /* font-weight: 500; */
  padding: 0.2rem 0.6rem;
  font-size:1em
}
.p-tooltip .p-tooltip-text {
  font-size: 15px;
}

.info {
  padding-bottom: 1em;
}

.tooltipBlue {
  color: rgb(194, 49, 49)!important;
  font-size: x-small;
  /* background: #2196F3; */
}

.tooltipBtn.p-button.p-button-icon-only {
  width: 15px;
  height: 18px;
}

.tooltipBtn .pi {
  font-size: 0.7rem;
}

.f-weight400 {
  font-weight: 400;
}

.f-weight700 {
  font-weight: 700;
}

.f-weight800 {
  font-weight: 800!important;
}

.informationHeader {
  margin-top: 0;
}

.sl-card.p-card {
  box-shadow: 0px 0px 5px 0px #c3c3c3;
}

.custom .p-scrollpanel-wrapper {
  border-right: 9px solid #f4f4f4;
}

.custom .p-scrollpanel-bar {
  background-color: #1976d2;
  opacity: 1;
  transition: background-color .3s;
}

.custom .p-scrollpanel-bar:hover {
  background-color: #135ba1;
}

.custom .completed, .hc {
  color: green;
}

.fs {
  color: rgb(50, 50, 182);
}

.custom .skipped {
  color: gray;
}

.custom .failed {
  color: red;
}

.cycle-timeline .custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

.payment-block {
    padding-bottom: 5em;
    padding: 0 1rem 0 1rem;
}

.p-timeline-horizontal .p-timeline-event-connector {
  width: 87%;
}

.notes-view.p-fieldset {
  cursor: pointer;
}

.notes-view.p-fieldset .p-fieldset-legend {
  padding: 0.2rem;
}

.notes-view.p-fieldset .p-fieldset-content {
  padding: 0.2rem;
}

.notes-editor.p-inputtext {
  width: 100%
}
.notes-editor.p-inputtext:enabled:focus {
  box-shadow: none;
}

.totals-row {
  background-color: #F8F8F8 !important;
  border-bottom: 2px solid #9099ad !important;
}

.match .draws, .bold-green {
  color: rgb(40 185 47) !important;
  font-weight: bold;
}
.dropouts {
  color: rgb(158, 9, 9)!important;
  font-weight: bold;
}
/* .sick-days-breach {
  background-color: lightcoral !important;
} */

.ambassador-row .p-tag.p-tag-info,
.ambassador-row .p-tag.p-tag-success {
  background-color: rgb(255 203 56) !important;
}

.p-tag-config {
  min-width: 32px;
}

.spine {
  background-color: #e6e6e6;
}



.total-fees {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}

.future-period {
  background: #d1f8ee;
}


.loggedInAs {
  cursor: pointer;
  background: red;
  color: white;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  max-width: 75%;
  z-index: 500;
  padding: 0.5em;
  border-bottom-right-radius: 1em;
  
}

@media only screen and (min-width: 600px){
  .video .onboarding_buttons {
    padding-left: 0em !important;
    padding-right: 17em;
  }

  .p-toast-top-right {
    top: 90px !important;
    right: 24px !important;
  }

  .p-toast {
    position: fixed;
    width: 20rem !important;
  }

  .desktop-card > div {
    border: 2px solid var(--primary-color-text);
    border-radius: 10px;
    padding: 20px;
    background-color: rgb(255 255 255 / 30%);
  }
}

.award-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background: white;
  z-index: 25;
}

.dot {
  background-color: #000;
  position: fixed;
  border-radius: 50%;
}

.bg-svg {
  position: fixed;
  height: 100%;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: -1;
}

/* slotmachine */

@import url('https://fonts.googleapis.com/css?family=Cairo');

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}



.icons:nth-child(2) {
  margin: 0 15px;
}

.winner-bg .icons { 
  border: 8px solid #f98827!important;
}

.spinner-container {
  overflow: hidden;
  height: 632px;  
  padding: 2em;
  transform: translate(-50%, -50%) scale(.62, .62);
  position: absolute;
  top: 340px;
  left: 50%;
  display: flex;
  transition: 0.3s transform;
  z-index: 1;
}

.app-wrap.winner-false {
  animation: linear 1s spin;
}

.spinner-container::after {
  position: absolute;
  content: '';
  display: block;
  height: 180px;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  left: 30px;
  background: rgba(255, 0, 0, .1);  
}

.gradient-fade {
  position: absolute;
  top: 32px;
  right: 32px;
  bottom: 32px;
  left: 32px;
}

.slot-title {
  padding-top: 75px;
}

.slot-title2 {
  margin-top: 380px;
}


.award-wrapper {
  padding-left: 17em!important;
}

.repeat-btn{
  display: block;
    margin-top: 400px;
    width: 200px;
}


.steps-bg {
  background: var(--primary-color);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 25;
  color: white;
}

.steps-bg > div,
.award-bg > div {
  max-width: 600px;
  max-height: 800px;
  display: flex;
  justify-content: center;
  padding: 0!important;
  margin: auto;
}



.vouchers {
  width: 800px;
  height: 100%;
  background-color: #fff;
  max-width: 800px;
  min-height: 400px;
  /* background: var(--surface-d); */
}

.voucher {
  border-radius: 1rem;
  border: none;
  box-shadow: 0 0.3rem 1rem 0 rgb(56 65 74 / 15%);
  background: #fff;
  margin: 1em 0;
  padding: 0.5em;
}


.voucher-img,
.voucher img {
  max-width: 150px;
  margin: 0.25em;
  border-radius: 1rem;
}

.v-price {
  padding: 0.2em;
  background-color: #edf2f9;
  border-radius: 10px;
}

.brand-img {
  object-fit: cover;
  width: 250px;
  height: 100px;
}

.brand-img.small {
  width: 50px;
  height: 44px;
}

/* .vouchers-dialog .p-dialog-content,
.vouchers-dialog .p-dialog-header,
.vouchers {
  background: #ccfff1;
} */

.vouchers-dialog .p-dialog-content {
  padding: 0 0 2rem 0;
}

.basket,
.vouchers {
  padding: 0 3.5rem;
}

.basket {
  padding: 0rem;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  /* box-shadow: 0px 0.3rem 1rem 0px rgb(56 65 74 / 15%); */
  box-shadow: 0 4px 2px -2px rgb(56 65 74 / 15%);
  /* border-radius: 0px 0px 10px 10px; */
}

.basket-editor.basket {
  box-shadow: none;
  border-bottom: 1px solid gray;
  border-radius: 0;
}

@media screen and (max-width: 1000px) {
  .spinner-container {
    transform: translate(-50%, -50%) scale(.55, .55);
  }
  .award-wrapper {
    padding-left: 2em!important;
  }
}


@media screen and (min-width: 1000px) {
  .winner-bg {
    background-size: 120%;
  }

  .rollgoals .onboarding_buttons {
    padding: 2em 0;
    display: flex;
    justify-content: center;
  }
  .rollgoals img {
    top: -130px;
}
/* 
  .spinner-container {
    top: 360px;
  } */

  .bottom-btns .onboarding_buttons {
    display: flex;
    justify-content: center;
    padding: 0;
  } 

  .fairydust {
    top: -65px;
  }

  .steps-bg .m-top-auto {
    margin-top: initial!important;
  }
  
}

@media screen and (max-width: 500px) {
  .winner-bg {
    background-size: auto;
  }
  .voucher {
    max-width: 380px;
  }

  .vouchers {
  padding: 0 1.5rem;
  max-width: 380px;
}
  
}
/* slotmachine */

.pad-sm {
  padding-left: 2em!important;
  padding-right: 2em!important;
}


.no-pad {
  padding: 0em!important;
}

.cup {
  width: 170px;
}

.txt-xxxl {
  font-size: 3em;
}

.txt-xxl {
  font-size: 2.5em;
}

.txt-xl {
  font-size: 2em;
}

.txt-md {
  font-size: 1.4em;
}

.txt-sm {
  font-size: 0.5em;
}

.txt-rem-xxxl {
  font-size: 3rem;
}

.txt-rem-xxl {
  font-size: 2.5rem;
}

.txt-rem-xl {
  font-size: 2rem;
}

.txt-rem-l {
  font-size: 1.8rem;
}

.txt-rem-md {
  font-size: 1rem;
}

.txt-rem-sm {
  font-size: 0.5rem;
}


.social-img {
  width: 40px;
  margin: 16px;
}

.inverted-btn {
  color: var(--primary-color);
  background: white;
}

.p-mt-cxl {
  margin-top: 8rem !important;
}

.rollgoals {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.rollgoals div {
  z-index: 4;
  /* margin-top: 5em; */
}

.rollgoals .joincircle {
  margin-top: 10em!important;
}

.rollgoals p,
.rollgoals h1,
.rollgoals h3 {
  z-index: 4;
  position:relative;
}

.rollgoals img {
  position: absolute;
  width: 100%;
}

.rollgoals img:first-child {
  opacity: 0.5;
}
.fairydust {
  /* width: 100px; */
  z-index: 1;
}
.rollgoals-img {
  width: 100px;
  z-index: 3;
}

.txt-white {
  color: white!important;
}

.txt-black {
  color: black!important;
}

.inpt {
  width: 100%;
  padding: 20px;
  background: #2c354a!important;
  border: 0px;
  border-radius: 8px;
  opacity: 1!important;
}
.inpt::placeholder {
  color: white;
}

.m-top-auto {
  margin-top:auto!important;
}


.avatar-wrapper {
  border-radius: 50%;
  width: 5em;
  height: 5em;
  margin-bottom: 1em;
}

.avatar-wrapper.bigger {
  width: 6em;
  height: 6em;
}

.avatar {
  position: absolute;
  top: 50%;
  width: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.drawn-salute {
  font-size: 2em;
  right: -0.3em;
  text-align: right;
  bottom: -1.2em;
}

.index-1 {
  z-index: 1;
}

.round-img {
  border-radius: 50%;
  width: 30%;
}

.getStartedTestimonials {
  font-size: 13px;
}
/* DELETE */
.loggedInAs {
  
  /* visibility: hidden; */
}
/* DELETE */

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #c5ffee;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.38) , rgba(34, 98, 198, 0));
    /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    transition: all ease 0.2s; */
}

.status-drawn{
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.product-badge.status-drawn {
  background: #feedaf;
  color: #b90b0b;
}

.status-drawn{
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.flex-wide {
  flex: 1 1 0%;
}

.active-menu-item {
  background: #c5ffee;
}


.rotate-90 {
  transform: rotate(90deg);
}

.notificationBar{
  background-color: var(--stepladder-green);
  color:var(--primary-color);
  padding:25px
}
.notificationBar .close > div{
  background-color: #3BCBA5;
  color:white;
  font-weight: bold;
  display: inline-block;
  padding: 10px 25px;
  border-radius: 10px;
  cursor: pointer;
}
.notificationBar .close{
  text-align: right;
}
.notificationBar .close i{
  font-size: 12px;
}
@media only screen and (min-width: 600px) {
  .notificationBar{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    z-index:100;
    padding:10px;
    opacity:0.9;
    text-align:center
  }
  .notificationBar > div:first-child{
    text-align: center !important;
    width:auto
  }
  .notificationBar .close, .notificationBar > div:first-child{
    display: inline-block;
  }
  .notificationBar .close{
    font-size:12px;
    margin-left:15px;
  }
  .notificationBar .close i{
    font-size:8px
  }
}

/* for mobile screen */
@media only screen and (max-width: 599px) {
}

/* table */

.blue-table.p-datatable .p-datatable-thead > tr > th {
  border-bottom-width: 0px;
  color: #495057;
  background: #e7f3ff!important;
}

.table-rounded.p-datatable .p-datatable-thead > tr > th {
  border-bottom-width: 0px;
  color: #495057;
  background: #e7eaff;
  text-align: center;
  vertical-align: middle;
}

.table-rounded.p-datatable .p-datatable-thead > tr > th:first-child { 
  border-radius: 0.25rem 0 0 0.25rem;
  box-shadow: -2px 2px 4px rgb(126 142 177 / 12%);
}
.table-rounded.p-datatable .p-datatable-thead > tr > th:last-child {
  border-radius: 0 0.25rem 0.25rem 0;
  box-shadow: 2px 2px 4px rgb(126 142 177 / 12%);
}

.table-rounded.p-datatable .p-datatable-thead > tr > th:not(:first-child):not(:last-child) {
  box-shadow: -1px 6px 4px -4px rgb(126 142 177 / 12%);
}

.table-rounded.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #d7dbf5;
  color: #495057;
}

/* .table-rounded .p-datatable-tbody > tr {
  box-shadow: 0px 2px 4px rgb(126 142 177 / 12%);
  transform: scale(1);
} */

.table-rounded table {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.table-rounded .p-datatable-tbody > tr td:not(:first-child):not(:last-child) {
  box-shadow: -1px 6px 4px -4px rgb(126 142 177 / 12%);
}

.table-rounded .p-datatable-tbody > tr > td {
  text-align: center;
  vertical-align: middle;
}

.table-rounded .p-datatable-tbody > tr td:first-child { 
  border-radius: .25rem 0 0 .25rem;
  box-shadow: -2px 2px 4px rgb(126 142 177 / 12%);
}
.table-rounded .p-datatable-tbody > tr td:last-child {
  border-radius: 0 .25rem .25rem 0;
  box-shadow: 2px 2px 4px rgb(126 142 177 / 12%);
}

.table-rounded .p-datatable-header {
  border-radius: .25rem;
  border: none;
  box-shadow: 0px 0.3rem 1rem 0px rgb(56 65 74 / 15%);
  background: white;
}

.table-rounded .p-paginator{
  box-shadow: 0px 0.3rem 1rem 0px rgb(56 65 74 / 15%);
  border-width: 0;
  border-radius: 0.25rem;
}

.table-rounded input[type=search]{
  width: 100%;
  font-size: 14px !important;
  background: #eaeaec;
  border: 1px solid #eaeaec;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 0px 4px 0px 40px;
  height: 36px;
  font-weight: 500;
}

.table-rounded.light .p-datatable-tbody > tr td:not(:first-child):not(:last-child) {
  box-shadow: none;
}

.table-rounded.light .p-datatable-tbody > tr td:first-child { 
  border-radius: 10px 0 0 10px;
  box-shadow: none;
}
.table-rounded.light .p-datatable-tbody > tr td:last-child {
  border-radius: 0 10px 10px 0;
  box-shadow: none;
}

.no-shadow-table .p-datatable-header,
.no-shadow-table .p-datatable-tbody > tr td,
.no-shadow-table .p-datatable-thead > tr > th{
  box-shadow: none!important;
}

.p-datatable-loading-overlay.p-component-overlay{
  background-color: rgb(236 253 255 / 40%);
}

.p-datatable-loading-icon.pi-spin.pi.pi-spinner {
  color: #2196f3;
}

/* table end */

.admin-login.p-card  {
  border-radius: 0.5em;
  padding: 1em;
  background: #e4f0ff;
  box-shadow: 0px 0.3rem 1rem 0px rgb(56 65 74 / 15%);
  border: 1px solid rgb(179 215 245 / 30%);
  margin: 1rem;
}

.admin-login .p-card-title {
  display: flex;
  justify-content: center;
  color: #6c757d;
}

.loc-title {
  color: var(--grey-wording);
}

.loc-amount {
  font-size: 1.5rem;
  /* color: var(--primary-color); */
  color: #00c591;
}

.p-dialog-content {
  border-radius: 0px 0px 8px 8px;
}
.p-dialog {
  border-radius: 8px;
}
.p-dialog .p-dialog-header {
  border-radius: 8px 8px 0px 0px;
}

.title-card {
  box-shadow: var(--light-shadow);
}

.title-card.p-card .p-card-content {
  padding: 0;
}

.warning-bg {
  background-color: #fffca8!important;
}

.p-tag.p-tag-success {
  background-color: #009688;
}

.p-badge.p-badge-success {
  color: #ffffff;
  background-color: #00c591;
}

.text-xxs{
  font-size: xx-small;
}

.text-xs{
  font-size: x-small;
}

.default-bg {
  background: #f2f2f7!important;
}


/* buttons */

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  background: #009688;
  border: 1px solid #009688;
}

.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #018477;
  border-color: #018477;
}

.disabled {
  opacity: 0.6;;
}

.p-badge.p-badge-secondary-light {
  background-color: #8ca1ab;
}

.vertical-align-center {
  vertical-align: middle;
}

.bg-red {
  background: #ff7a7a;
}

.bg-yellow {
  background: #f8ee5c;
}

.bg-green {
  background: #4da772;
}

.p-tieredmenu .p-submenu-list {
  z-index: 3;
  padding: 0;
}
/* tracker tooltip */

.custom-tooltip {
  position: absolute!important;
  width: 150px;
  height: 70px;
  border: 1px solid cornflowerblue;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

.m-width-100 {
  max-width: 100px;
}

.bonus {
  font-size: 12px;
}

.black {
  color: black!important;
}

.arrow-text {
  font-size: 1.5em;
  color: green;
}

.border-bottom-gray {
  border-bottom: 1px solid #80808061;
}

.font-sm {
  font-size: small;
}

.font-md {
  font-size: medium;
}

.font-lg {
  font-size: large;
}

.font-xlg {
  font-size: x-large;
}

.vouchers .total-wallet {
  border-radius: 10px;
  border: 1px solid #bbbbbb;
}

.total-wallet .pi {
  font-size: 3.5rem;
  width: 100px;
  transform: rotate(90deg);
  color: #bbbbbb;
}

.card-rounded {
  box-shadow: 0px 10px 40px rgb(41 50 65 / 10%);
  border-radius: 18px;
}

.vendor-name{
  color: grey;
}

.vendor-name span{
  color: #495057;
  font-weight: bold;
}

.vendor-info {
  border: 1px solid;
  border-radius: 6px;
}

.vendor-tag {
  padding: 0.5rem 0.5rem;
  border-width: 2px!important;
}

.cursor-arrow {
  cursor: default!important;
}

.info-icon {
  font-size: 1.5em;
}

.bd-bottom {
  border-bottom: 1px solid #2f3c5b;
}
.bd-bottom-yellow {
  border-bottom: 1px solid #fbbf2c;
}

.green {
  color: green;
}

.blue {
  color: rgb(49, 11, 173);
}

.red {
  color: #4e4a4a;
}

.brown {
  color: #af7070;
}

.orange {
  color: orange;
}

.overflow-auto {
  overflow: auto;
}

.divider {
  border-bottom: 1px solid #ededed;
}

.divider.bolder {
  border-bottom: 3px solid #ededed;
}

.border-2px{
  border-width: 2px!important;
}

.edit-button {
  position: absolute;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-top: 0.9rem!important;
}

.max-width-600 {
  max-width: 600px;
}

.max-width-300 {
  max-width: 300px;
}

.max-width-400 {
  max-width: 400px;
}

.max-width-500 {
  max-width: 500px;
}

.height-65px {
  height: 65px;
}

.informationLabel, .informationHeader {
  margin-bottom: 2em;
}
.renewal-dialog {
  max-width: 500px;
}

.renewal-dialog .p-dialog-content {
  border-radius: 0;
}

.purple-gradient-bottom,
.renewal-dialog.purple .p-dialog-footer {
  background-image: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(217, 200, 231) 100%);
}

/* .renewal-dialog .p-dialog-header {
  background-image: linear-gradient(0deg, rgb(217, 200, 231) 0%, rgb(255, 255, 255) 100%);
} */

.renewal-dialog .p-card {
  box-shadow: var(--neumorph-shadow);
}

.renewal-dialog.purple .p-dialog-content {
  background: var(--stepladder-purple);
}
/* 
.renewal-dialog > .p-dialog-content {
  border-radius: 0!important;
  max-width: 500px;
} */

.tile {
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    max-width: 200px;
    box-shadow: var(--neumorph-shadow);
}

.tile.first {
  margin-right: 0;
  margin-left: auto;
}

.calendar {
  width: 100%;
  height: 50px;
}

.calendar .p-inputtext {
  border-radius: 10px 0 0 10px;
}

.calendar .p-button {
  background: #0288D1;
  border: 1px solid #0288D1;
}

.calendar .p-button:enabled:hover {
  background: #027abc;
  border-color: #027abc;
}

.calendar .p-button:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #89d4fe;
  box-shadow: 0 0 0 0.2rem #89d4fe;
}

.platform_small > div {
  max-width: 600px!important;
}

.raising {
  border: 1px solid #e3e8ee;
  border-radius: 8px;
}

.border-r {
  border-right: 1px solid #e3e8ee;
}

.input-img {
  top: -5px;
  width: 60px;
  position: absolute;
  right: 0;
}

.bg-grey {
  background: var(--surface-c);
}

.text-sm {
  font-size: 0.8rem;
}

.isa-story{
  background: #4AE9BF;
  height: 100%;
  max-height: 731px;
  max-width: 411px;
}

.paygate-table .p-column-filter {
  max-width: 150px;
  width: 100%;
}

.paygate-table .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5em;
}

.badge-border {
  box-shadow: 0px 0px 0px 2px var(--text-color-secondary);
  color: black!important;
  background-color: white!important;
  border: none;
}

.badge-no-border {
  /* box-shadow: 0px 0px 0px 2px var(--text-color-secondary); */
  color: black!important;
  background-color: white!important;
  border: none;
}

.spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1200;
}

.progress-spinner {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.banner-centered {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 30em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  padding: 2em;
  border-radius: 0.5em;
  background: #e4f0ff;
  box-shadow: 0px 0.3rem 1rem 0px rgb(56 65 74 / 15%);
}

.admin-page-wrapper {
  padding: 1.5rem !important;
  overflow-y: auto;
}

.admin-page-wrapper > h1:first-child {
  margin-top: 0;
}

.page-title {
  font-size: 2rem!important;
}

.subTitle {
  font-weight: 500;
  margin-bottom: 1.5rem !important;
  margin: 0;
  color: var(--text-color-secondary);
}

.topsheet-table {
  height: 100%;
  width: 100%;
  position: relative;
}

ul.stages {
  list-style-type: none;
  padding-left: 20px;
}

.border-grey {
  border: 1px solid var(--text-color-secondary);
}

.min-width-200 {
  min-width: 200px;
}

.min-width-60 {
  min-width: 60px;
}


.dropoutsTable tbody > tr:nth-child(1){
font-weight: bold !important;
}

.dropoutsTable tbody > tr:nth-child(2), .dropoutsTable tbody > tr:nth-child(3){
  font-weight: bold !important;
  font-size:14px;
}

.dropoutsTable tbody > tr:nth-child(4), .dropoutsTable tbody > tr:nth-child(5){
  font-size:11px !important
}

.p-card {
  border-radius: 14px;
}

.label-inline {
  min-width: 60px;
  font-weight: 600;
  color: var(--text-color-secondary);
}

.small-buttons {
  max-height: 35px;
}

.tracker-table-big .ag-ltr .ag-cell[title="reserved"] {
  padding-left: 4px;
}

.tracker-table-big .reserved-tag {
  padding: 0.25rem 0.2rem;
}

.mini-menu .p-menuitem-icon.pi.pi-fw.pi-bars:before {
  margin-right: -8px;
}
.mini-menu .p-menuitem-icon.pi.pi-fw.pi-power-off:before {
  margin-right: -8px;
}

.logo-wrapper {
  /* transition: width .3s; */
  /* transition: height .3s; */
}

.mini-menu .logo-wrapper {
  width: 60px;
  height: 60px;
}


.arrearsTable .p-sortable-column-icon{
  font-size:10px
}

.arrearsTable .p-column-title{
  font-size: 14px
}

.arrearsTable .p-datatable-tbody td:last-child{
  font-size:11px
}

.arrearsTable .p-filter-column .p-inputtext{
  width:80px;
  font-size:11px
}

.pure-dialog .p-dialog-header {
  display: none;
}

.pure-dialog .p-dialog-content{
  padding: 0;
  background-color: rgb(255 255 255 / 0%);
}
.no-padding-dialog .p-dialog-content {
  padding: 0;
}

.toggle-cancel.p-togglebutton.p-button.p-highlight {
  background: #ffffff !important;
  border-color: #ced4da !important;
  color: #463535 !important;
}

.addresses .p-listbox-item {
  border-bottom: 1px solid var(--stepladder-purple)!important;
}

.toolbar-purple {
  border: 1px solid var(--stepladder-purple);
}

.toolbar-no-styles {
  border: none;
  background: none;
  padding: 0 !important;
}

.drawday {
  background: #2f3c5b;
  color: white!important;
  /* background-image: url('/drawday/drawday-bg.png'); */
}

.partner-popup {

  /* background-position: center!important;
  background-size: cover!important;
  background-repeat: no-repeat!important; */
  z-index: 55;
}

.b-radius-10 {
  border-radius: 10px;
}
.b-radius-10-calendar > input {
  border-radius: 10px;
}

.p-mt-7 {
  margin-top: 5rem!important;
}

.bg-white {
  background: white;
}

.partner-card {
  border: 1px solid var(--stepladder-green);
  border-radius: 8px;
  max-width: 350px;
}

.drawDayContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 54;
  background: white;
  overflow-x: auto;
}

.green-textarea:focus {
  border-color: var(--stepladder-green)!important;
}

.dark-dialog .p-dialog-content { 
  background: var(--primary-color);
  color: white;
  padding-bottom: 0 !important;
  max-width: 400px
}

.p-st-green {
  color: var(--stepladder-green);
}

.p-st-purple {
  color: #ba88e4;
}

.width-input-6em input[type=text] {
  width: 6em !important;
}

.p-button.p-button-stepladder, .p-button:enabled:hover, .p-buttonset.p-button-stepladder > .p-button, .p-splitbutton.p-button-stepladder > .p-button {
  background: var(--stepladder-green);
  border: 1px solid var(--stepladder-green);
  color: #333D5A;
}
.p-button-stepladder:enabled:hover {
  background: var(--stepladder-green)!important;
  border: 1px solid var(--stepladder-green)!important;
}
.admin-login-page {
  width: 100%;
  height: 100%;
  position: fixed;
  background: white;
  top: 0;
  left: 0;
}
.scrollable{
  overflow-y: auto;
}

.scrollable-y{
  overflow-x: hidden;
  overflow-y: auto;
}

.no-border {
  border: 0!important;
}

.button-purple {
  background: #B98CEB!important;
  border: #B98CEB!important;
}

.button-st-green {
  background: var(--stepladder-green)!important;
  border: var(--stepladder-green)!important;
}

.drawdyCounter {
  color: var(--stepladder-green);
  font-size: 8rem;
}

.gif_player {
  display: flex;
  flex-wrap: wrap;
}

.gif_player img {
  width: 100%;
}

.cookie {
  width: 150px;
  left: -100px;
  position: absolute;
  padding-top: 100px;
}

.partners-header {
  position: absolute;
  width: 100%;
}

.hoverView{
  position: absolute;
  top: 0;
  background: white;
}

.upload-input {
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  z-index: 5;
  cursor: pointer;
}

.admin-view-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index:  500;
  cursor: "pointer";
  font-weight: 700;
  background-color: #d5ffdb;
  font-size: 13px;
  min-height: 40px;
}

.admin-settings-btn {
  position: fixed;
  right: 0;
  z-index: 501;
  cursor: pointer;
}


.group-tag {
  line-height: 1;
  opacity: 0.7;
}